<template>
  <div class="search-item">
    <a
      class="search-item__img"
      :href="lotLink"
      target="_blank"
      rel="nofollow noopener"
      @touchmove.stop
    >
      <ui-hover-slider
        v-if="item.images.length"
        class="search-item__img-slider"
        :images="wrappedImages"
        :width-quotient="imgWidthQuotient"
      >
        <template
          v-if="item.images.length > imagesLimit"
          slot="more"
        >
          {{ $tc('SHOW_IMAGES_BTN') }}
        </template>
      </ui-hover-slider>

      <div
        v-else
        class="search-item__img-stub"
      >
        <ui-icon
          class="search-item__img-stub-icon"
          icon="no-car"
        />
      </div>
    </a>

    <div class="search-item__main">
      <a
        class="search-item__title-wrp"
        :href="lotLink"
        target="_blank"
        rel="nofollow noopener"
      >
        <h3
          class="search-item__title"
          :title="item.name.toUpperCase()"
        >
          {{ item.name.toUpperCase() }}
        </h3>
      </a>

      <div class="search-item__desc">
        <p
          class="search-item__location"
          :title="item.location"
        >
          <ui-icon
            class="search-item__location-icon"
            icon="pin"
          />

          <span class="search-item__location-txt">
            {{ item.location }}
          </span>
        </p>

        <ui-countdown
          v-if="!item.isSale"
          class="search-item__countdown"
          :start="item.createdAt"
          :end="item.endTime"
          :closed-at="item.endedAt"
          :text-days="$t('COMMON.DAYS')"
          :text-hours="$t('COMMON.HOURS')"
          :text-minutes="$t('COMMON.MINUTES')"
          :date-formatter="$fd"
        />
      </div>

      <div class="search-item__block search-item__block_ids">
        <div class="search-item__id">
          <p class="search-item__id-lbl">
            <ui-copy-button
              class="search-item__id-copy"
              :value="item.id"
              :copied-message="$t('COMMON.COPIED_MSG')"
            >
              {{ $t('ID_LBL') }}
            </ui-copy-button>
          </p>

          <p class="search-item__id-val">
            {{ item.id }}
          </p>
        </div>

        <div class="search-item__id">
          <template v-if="item.isCopartAuction || item.isIaaiAuction">
            <p class="search-item__id-lbl">
              <ui-copy-button
                class="search-item__id-copy"
                :value="item.externalAuctionId"
                :copied-message="$t('COMMON.COPIED_MSG')"
              >
                <template v-if="item.isCopartAuction">
                  {{ $t('COPART_LBL') }}
                </template>

                <template v-else>
                  {{ $t('IAAI_LBL') }}
                </template>
              </ui-copy-button>
            </p>

            <a
              v-if="item.isCopartAuction && item.externalAuctionId"
              class="search-item__id-link"
              :href="`https://copart.com/lot/${item.externalAuctionId}`"
              target="_blank"
              rel="nofollow noopener"
            >
              {{ item.externalAuctionId }}
            </a>

            <p
              v-else
              class="search-item__id-val"
            >
              {{ item.externalAuctionId || $t('COMMON.SYMBOLS.MDASH') }}
            </p>
          </template>
        </div>

        <div class="search-item__id">
          <p class="search-item__id-lbl">
            <ui-copy-button
              class="search-item__id-copy"
              :value="item.vinCode"
              :copied-message="$t('COMMON.COPIED_MSG')"
            >
              {{ $t('VIN_LBL') }}
            </ui-copy-button>
          </p>

          <p class="search-item__id-val">
            {{ item.vinCode || $t('COMMON.SYMBOLS.MDASH') }}
          </p>
        </div>
      </div>

      <div class="search-item__block search-item__block_prices">
        <div
          v-if="isBidShown"
          class="search-item__price search-item__price_auction"
        >
          <span class="search-item__price-amount">
            {{ $fc(item.highestBid || item.startPrice, item.currency) }}
          </span>

          <span class="search-item__price-desc">
            {{ $t('HIGHEST_BID_TXT') }}
          </span>
        </div>

        <div
          v-if="item.isSale || item.buyNowPrice"
          class="search-item__price search-item__price_sale"
        >
          <span class="search-item__price-amount">
            <template v-if="item.isDirectSale">
              {{ $fc(item.customerTotalPrice, item.currency) }}
            </template>

            <template v-else>
              {{ $fc(item.buyNowPrice, item.currency) }}
            </template>
          </span>

          <span class="search-item__price-desc">
            {{ $t('BUY_NOW_TXT') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UiHoverSlider,
  UiCountdown,
  UiCopyButton,
  UiIcon,
  breakpointsV2,
  viewport
} from '@shelf.network/ui-kit'

import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'

import { generateImageProps } from 'Utils/generateImageProps'
import { LotFrontOffice } from 'Models/LotFrontOffice'

const IMAGES_LIMIT = 4
const thumbnailSrcSet = [
  { width: 240, height: 180 },
  { width: 320, height: 240 },
  { width: 480, height: 360 },
  { width: 640, height: 480 }
]
const thumbnailSizes = [
  `(max-width:${breakpointsV2.SM}px) 40vw`,
  '20vw'
].join(', ')
const placeholder = { width: 32, height: 24 }

export default {
  name: 'search-item',
  components: {
    UiCountdown,
    UiIcon,
    UiHoverSlider,
    UiCopyButton
  },

  props: {
    item: { type: LotFrontOffice, required: true },
  },

  computed: {
    ...mapGetters('entities/user', {
      platform: userGetters.PLATFORM,
    }),

    imgWidthQuotient () {
      return viewport.isSmall ? 0.6 : 1
    },

    lotLink () {
      return `${this.platform.clientUrl}/lot/${this.item.id}`
    },

    wrappedImages () {
      return this.item.images
        .slice(0, IMAGES_LIMIT)
        .map((image, index) => generateImageProps({
          src: image,
          alt: `${this.item.name} [${index}]`,
          srcSetOptions: thumbnailSrcSet,
          sizes: thumbnailSizes,
          placeholderOptions: placeholder,
          progressive: true
        }))
    },

    isBidShown () {
      const isPricesEqual = this.item.startPrice &&
        this.item.startPrice === this.item.buyNowPrice
      return !this.item.isSale && !isPricesEqual
    },

    imagesLimit: () => IMAGES_LIMIT,
  },
}
</script>

<style lang="scss" scoped>
.search-item {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 21em minmax(0, 1fr);
  align-items: center;
  color: $color-dark;
  border-radius: 1em;
  background-color: $color-white;
  transition: box-shadow ease-in-out 300ms;
  box-shadow: 0 2px 10px rgba($color-black, 0.08);
  // HACK: Fix card height & border-radius in Safari
  height: max-content;
  z-index: z-index(0);

  @include respond-below(sm) {
    grid-template-columns: minmax(0, 1fr);
  }

  &:hover {
    @include respond-above(sm) {
      box-shadow: 0 4px 16px rgba($color-black, 0.12);
    }
  }

  &__img {
    &-slider,
    &-stub {
      --ratio: calc(4 / 3);

      @include respond-below(sm) {
        --ratio: 2;
      }
    }

    &-stub {
      @include aspect-ratio;

      position: relative;
      overflow: hidden;
      background-color: $color-light-grey;

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 64px;
        color: $color-grey;
      }
    }
  }

  &__main {
    padding: 1.6em;
  }

  &__title-wrp {
    text-decoration: none;
    color: $color-dark;

    &:hover {
      text-decoration: underline;
    }
  }

  &__title {
    @include ellipsis;

    font-size: 1.1em;
  }

  &__desc {
    color: $color-dark-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__location {
    @include ellipsis;
  }

  &__countdown {
    margin-left: 1em;
    display: flex;
    align-items: center;
    font-size: 1em;

    /deep/ .ui-countdown {
      &__text {
        margin-left: 0.4em;
      }
    }
  }

  &__block {
    border-top: 1px solid $color-ui-grey-blue2;
    margin-top: 1em;
    padding-top: 1em;

    &_ids {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1em;

      @include respond-below(sm) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &_prices {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__id {
    display: flex;
    flex-direction: column;

    &-lbl {
      color: $color-dark-grey;
    }

    &-val,
    &-link {
      margin-top: 0.2em;
    }

    &-link {
      color: $color-sys-info;
    }
  }

  &__price {
    white-space: nowrap;
    display: flex;
    align-items: center;

    // HACK: Fix font rendering in iOS Safari
    transform: perspective(1px) scale(1) translateZ(0);
    padding-bottom: 1px;

    &_auction {
      color: $color-lot-auction;
    }

    &_sale {
      color: $color-lot-sale;
    }

    &-amount {
      font-size: 1.1em;
    }

    &-desc {
      margin-left: 0.4em;
      color: $color-dark;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SHOW_IMAGES_BTN": "Show all images",
    "ID_LBL": "ID",
    "COPART_LBL": "Copart",
    "IAAI_LBL": "IAAI",
    "VIN_LBL": "VIN Code",
    "HIGHEST_BID_TXT": "Highest Bid",
    "BUY_NOW_TXT": "Buy Now"
  },
  "ka": {
    "SHOW_IMAGES_BTN": "სურათების ნახვა",
    "ID_LBL": "ID",
    "COPART_LBL": "Copart",
    "IAAI_LBL": "IAAI",
    "VIN_LBL": "VIN კოდი",
    "HIGHEST_BID_TXT": "მაღალი ბიჯი",
    "BUY_NOW_TXT": "ყიდვა"
  },
  "ru": {
    "SHOW_IMAGES_BTN": "Просмотреть все фото",
    "ID_LBL": "ID",
    "COPART_LBL": "Copart",
    "IAAI_LBL": "IAAI",
    "VIN_LBL": "VIN код",
    "HIGHEST_BID_TXT": "Наивысшая ставка",
    "BUY_NOW_TXT": "Купить"
  },
  "uk": {
    "SHOW_IMAGES_BTN": "Переглянути всі зображення",
    "ID_LBL": "ID",
    "COPART_LBL": "Copart",
    "IAAI_LBL": "IAAI",
    "VIN_LBL": "VIN код",
    "HIGHEST_BID_TXT": "Найвища ставка",
    "BUY_NOW_TXT": "Купити"
  }
}
</i18n>
