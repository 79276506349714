export const searchActions = {
  LOAD_LIST: 'LOAD_LIST',
  LOAD_MORE: 'LOAD_MORE',
}

export const searchMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_FETCH_NEXT: 'SET_FETCH_NEXT',
  SET_LIST: 'SET_LIST',
  PUSH_LIST: 'PUSH_LIST',
}

export const searchGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  FETCH_NEXT: 'FETCH_NEXT',
}
