<template>
  <div class="search">
    <page-subnav class="search__subnav">
      <subnav-search
        class="search__subnav-action"
        :value="$route.query.search"
        :placeholder="$t('SEARCH_PH')"
        active
        @input="onSearch"
      />
    </page-subnav>

    <search-list
      class="search__list"
      :list="list"
      :load-more="loadMore"
      @update-list-ask="loadList"
      @more-ask="loadMore"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'
import SearchList from './components/SearchList'

import { mapActions, mapMutations, mapGetters } from 'vuex'
import { searchActions, searchMutations, searchGetters } from './store/types'

import { USER_CLAIMS } from 'Constants/userClaims'
import { config } from '@/config'

import { showError } from 'Utils/notifications'
import debounce from 'lodash/debounce'

export default {
  name: 'search',
  components: {
    PageSubnav,
    SubnavSearch,
    SearchList,
  },

  computed: {
    ...mapGetters('ui/search', {
      list: searchGetters.LIST,
      isLoading: searchGetters.IS_LOADING,
    }),
  },

  async created () {
    await this.loadList()
  },

  methods: {
    ...mapActions('ui/search', {
      loadListAction: searchActions.LOAD_LIST,
      loadMore: searchActions.LOAD_MORE,
    }),

    ...mapMutations('ui/search', {
      setIsLoading: searchMutations.SET_IS_LOADING,
    }),

    onSearch (str) {
      str = str.trim()
      if ((this.$route.query.search || '') === str) return
      this.setIsLoading(true)
      this.applySearchDebounced(str)
    },

    applySearchDebounced: debounce(async function (search) {
      await this.applySearch(search)
    }, 1000),

    applySearch (str) {
      const query = { ...this.$route.query }
      if ((query.search || '') === str) return
      query.search = str || undefined

      this.$router.push({ query })
      this.loadList()
    },

    async loadList () {
      try {
        const payload = this.buildLoadListPayload()
        await this.loadListAction(payload)
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },

    buildLoadListPayload () {
      const result = { filter: {}, include: 'vehicle' }
      const query = this.$route.query || {}

      const isLotId = /^\d{8}$/.test(query.search || '')
      const isExtensionSearch = this.$can(USER_CLAIMS.EXTENSION_WON) &&
        !(this.$can(USER_CLAIMS.LEADS) || this.$can(USER_CLAIMS.ACTIVE_TRADES))

      if (query.search) {
        result.filter.search = isExtensionSearch && isLotId
          ? `copart-${query.search}`
          : query.search
      }

      if (isExtensionSearch) {
        result.filter.lotPlatforms = config.COPART_PLATFORM_ID
      }

      return result
    },
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__subnav-action {
    margin-left: auto;

    @include respond-below(sm) {
      width: 100%;
    }
  }

  /deep/ .page-subnav {
    &__content {
      @include respond-below(sm) {
        padding: 0 1.6em;
      }

      &-main {
        display: flex;
        justify-content: flex-end;
      }

      &-right {
        display: none;
      }
    }
  }

  /deep/ .subnav-search {
    .ui-text {
      @include respond-below(sm) {
        width: 100%;
      }

      &__input {
        width: 30em;

        @include respond-below(sm) {
          width: 100%;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SEARCH_PH": "Search by VIN, ID, external ID",
    "META_TITLE": "Search",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the lots list. Please try again later or contact the system owner."
  },
  "ka": {
    "SEARCH_PH": "მოძებნე VIN, ლოტის ID-ით",
    "META_TITLE": "ძიება",
    "LIST_FETCH_FAILED_NOTIFY": "სია ვერ ჩაიტვირთა. მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "SEARCH_PH": "Поиск по VIN, ID",
    "META_TITLE": "Поиск",
    "LIST_FETCH_FAILED_NOTIFY": "Не удалось загрузить список лотов. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "SEARCH_PH": "Пошук за VIN, ID",
    "META_TITLE": "Пошук",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдалось завантажити список лотів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
