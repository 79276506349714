<template>
  <list-grouped
    class="search-list"
    :list="list"
    :list-total-count="0"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="SEARCH_PAGE_LIMIT"
    :load-more="loadMore"
    :item-component="SearchItem"
    :item-skeleton-component="SearchItemSkeleton"
    :columns="2"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import ListGrouped from 'Common/ListGrouped'

import SearchItem from './SearchItem'
import SearchItemSkeleton from './SearchItemSkeleton'

import { mapGetters } from 'vuex'
import { searchGetters } from '../store/types'

import { SEARCH_PAGE_LIMIT } from '../constants'
import { validateArrayOf } from 'Models/modelUtils'
import { LotFrontOffice } from 'Models/LotFrontOffice'

export default {
  name: 'search-list',
  components: {
    ListGrouped,
  },

  props: {
    list: {
      type: Array,
      required: true,
      validator: validateArrayOf(LotFrontOffice),
    },

    loadMore: {
      type: Function,
      required: true,
    },
  },

  data () {
    return {
      SearchItem,
      SearchItemSkeleton,
      SEARCH_PAGE_LIMIT,
    }
  },

  computed: {
    ...mapGetters('ui/search', {
      isLoading: searchGetters.IS_LOADING,
      isNextLoading: searchGetters.IS_NEXT_LOADING,
    }),
  },
}
</script>

<style lang="scss" scoped>
.search-list {
  --list-padding-side: 3em;

  @include respond-below(sm) {
    --list-padding-side: 1.6em;
  }

  /deep/ .list-grouped {
    &__items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.6em;
      margin-top: 1.6em;

      @include respond-below(sm) {
        grid-template-columns: 1fr;
      }
    }

    &__list-inner {
      min-width: auto;
    }
  }
}
</style>
